import * as React from "react";
import styled from "@emotion/styled";
import { COLOR_WHITE, COLOR_BLACK } from "../color";
import "../components/layout.css";
import { H1, H2, H3, H4, H5, Paragraph, Item, List, Lead, Blockquote, Link } from "../components/typography2";
import { Footer } from "../components/footer";
import { Inset } from "../components/layout";

const Container = styled.div`
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    background-color: #${COLOR_WHITE};
    font-family: Inter;
    color: #000;
`;

const PrivacyPolicy = () => {
    return (
        <Container>
            <Inset>
                <H1>Privacy Policy</H1>
                <Lead>Adopted: June 01, 2022</Lead>
                <H2>1. Identity and contact details of the controller</H2>
                <Paragraph>The controller within the meaning of the General Data Protection Regulation (hereinafter “GDPR”) and other national data protection laws of EU countries and other data protection laws is:</Paragraph>
                <Blockquote>
                    Skyward Assembly GmbH<br />
                    Carlsgrüner Weg 15b<br />
                    95192 Lichtenberg<br />
                    Germany<br />
                    <br />
                    <Link to="https://www.skywardassembly.com" target="_blank">https://www.skywardassembly.com</Link><br />
                    Email: <Link to="mailto:privacy@skywardassembly.com" target="_blank">privacy@skywardassembly.com</Link><br />
                </Blockquote>
                <Paragraph>(hereinafter referred to as "we", "us" or "our")</Paragraph>
                <H2>2. Data processing on our website</H2>
                <H3>2.1. Website functions</H3>
                <H4>2.1.1 Temporary storage of your personal data</H4>
                <H5>Legal basis</H5>
                <Paragraph>Legal basis for the processing of your personal data in the context of providing the website and the creation of log files is Art. 6 para. 1 lit. f  GDPR.</Paragraph>
                <H5>Purpose</H5>
                <Paragraph>This website is hosted by the external service provider <Link to="https://www.netlify.com/" target="_blank">Netlify</Link> (hereinafter referred to as “hoster”). The hoster processes your personal data and stores it in log files. The data processing is automated and is necessary for the provision and security of the web offer and its improvement. This information can be found in <Link to="https://www.netlify.com/privacy/" target="_blank">Netlify's privacy policy</Link>, as well as <Link to="https://www.netlify.com/gdpr/" target="_blank">Netlify's GDPR policy</Link>.</Paragraph>
                <Paragraph>Personal data collected on this website is stored on the hoster's servers. This may include, but is not limited to, contact requests, meta and communication data, contact details, names, website accesses and other data generated via the website.</Paragraph>
                <Paragraph>Our hoster will only process your data to the extent necessary to fulfil its service obligations and follow our instructions regarding this data.</Paragraph>
                <H5>Duration of storage</H5>
                <Paragraph>The data are erased as soon as they are no longer necessary to achieve the purpose for which they were collected. The log files will be erased after 30 days. </Paragraph>
                <H5>Objection and rectification option</H5>
                <Paragraph>The collection of your personal data for the provision of the website and the storage of your personal data in log files is necessary in order to operate the website. Consequently, you cannot object to this data processing.</Paragraph>
                <H4>2.1.2 Use of technically necessary cookies</H4>
                <H5>Legal basis</H5>
                <Paragraph>Legal basis for the processing of your personal data in the context of providing the website and the creation of log files is Art. 6 para. 1 lit. f GDPR.</Paragraph>
                <H5>Purpose</H5>
                <Paragraph>The purpose of using technically necessary cookies is to make it easier for you to use our website. Some functions of our website cannot be offered without the use of cookies. For this, it is necessary that your browser is recognised again after a page change. Your personal data will not be processed in any other way.</Paragraph>
                <H5>Duration of storage</H5>
                <Paragraph>The data are erased as soon as they are no longer necessary to achieve the purpose for which they were collected; this happens, in particular, as soon as the relevant session has ended.</Paragraph>
                <H5>Objection and rectification option</H5>
                <Paragraph>An overview of the technically necessary cookies used on our website can be found at the bottom of the page.</Paragraph>
                <Paragraph>Cookies are stored on your computer and transmitted from the computer to our website. Therefore, you have complete control over the use of cookies.</Paragraph>
                <Paragraph>By adjusting the settings of your browser, you can deactivate or restrict the transmission of cookies. You can also, at any time, delete cookies that have already been stored. This can also be done automatically. However, if you deactivate cookies for our website, you might not be able to use all website's functions to their full extent.</Paragraph>
                <H3>2.2. Communication and marketing</H3>
                <H4>2.2.1 Newsletter</H4>
                <H5>Legal basis</H5>
                <Paragraph>Legal basis for the processing of your personal data in the context of sending the newsletter is your given consent, Art. 6 para. lit. a GDPR.</Paragraph>
                <H5>Purpose</H5>
                <Paragraph>The processing of your personal data in this context serves to send the newsletter to you, in order to provide you with updates.</Paragraph>
                <Paragraph>We utilize the external service provider <Link to="https://mailchimp.com/" target="_blank">Mailchimp</Link> for our newsletter and email marketing. More information can be found in <Link to="https://www.intuit.com/privacy/statement/" target="_blank">Mailchimp’s privacy statement</Link>, as well as <Link to="https://mailchimp.com/legal/cookies/" target="_blank">Mailchimp’s cookie statement</Link>.</Paragraph>
                <H5>Duration of storage</H5>
                <Paragraph>Your personal data are erased as soon as they are no longer necessary to achieve the purpose for which they were collected. Your personal data will be stored until you unsubscribe from our newsletter.</Paragraph>
                <H5>Objection and rectification option</H5>
                <Paragraph>You can withdraw your consent to receiving the newsletter at any time or use the unsubscribe link included in each newsletter to object to receiving the newsletter for the future.</Paragraph>
                <H3>2.3. Data protection and legal disputes</H3>
                <H4>2.3.1 Data subjects' requests pursuant to Art. 12 et seq. GDPR</H4>
                <H5>Legal basis</H5>
                <Paragraph>Legal basis for the processing of your personal data in the context of processing your data protection related-request (“data subjects' request”) is Art. 6 para. lit. c in connection with Art. 12 et seq. GDPR. Legal basis for the subsequent documentation regarding the handling of your data subject's request in accordance with the legal provisions is Art. 6 para. lit. f GDPR.</Paragraph>
                <H5>Purpose</H5>
                <Paragraph>The purpose of processing your personal data in the context of handling your data subject's request is to answer your data protection-related enquiry. The subsequent documentation regarding the processing of your request is to fulfill the obligation of accountability required by law, Art. 5 (2) GDPR.</Paragraph>
                <H5>Duration of storage</H5>
                <Paragraph>The data are erased as soon as they are no longer necessary to achieve the purpose for which they were collected. In the case of the processing of data subject's requests, pursuant to § 41 BDSG (German Federal Data Protection Act, “Bundesdatenschutzgesetz” - “BDSG”) in connection with § 31 (2) Nr. 1 OWiG (German Regulatory Offences Act, “Gesetz über Ordnungswidrigkeiten” – “OWiG”), this is three years after the conclusion of the respective procedure.</Paragraph>
                <H5>Objection and rectification option</H5>
                <Paragraph>You can object to the processing of your personal data for the future at any time in the context of the processing of data subject's requests. In this case, however, we will not be able to process your request any further.</Paragraph>
                <Paragraph>The documentation of the processing of the respective data subject's request is mandatory by law. Consequently, you cannot object to this data processing.</Paragraph>
                <H4>2.3.2 Defending and enforcing legal claims</H4>
                <H5>Legal basis</H5>
                <Paragraph>Legal basis for the processing of your personal data in the context of defending and enforcing legal claims is Art. 6 para. lit. f GDPR.</Paragraph>
                <H5>Purpose</H5>
                <Paragraph>The purpose of processing your personal data in the context of defending and enforcing legal claims is the defense of unjustified claims and the legal enforcement of claims and rights.</Paragraph>
                <H5>Duration of storage</H5>
                <Paragraph>The data are erased as soon as they are no longer necessary to achieve the purpose for which they were collected.</Paragraph>
                <H5>Objection and rectification option</H5>
                <Paragraph>The processing of your personal data in the context of defending and enforcing legal claims is absolutely necessary for the defense and enforcement thereof. Consequently, you cannot object to this processing.</Paragraph>
                <H2>3. Categories of recipients</H2>
                <Paragraph>Within our company, the offices and departments that receive personal data are those that need them to fulfill the aforementioned purposes. In some cases, we work together with different service providers and therefore transfer your personal data to other trusted recipients. These can include:</Paragraph>
                <List>
                    <Item>IT service providers</Item>
                </List>
                <H2>4. Data transfers to third countries</H2>
                <Paragraph>As part of the processing of your personal data, we may transfer your personal data to trustworthy and specially selected service providers in third countries. Third countries are countries outside of the European Union (EU) or the European Economic Area (EEA).</Paragraph>
                <Paragraph>We only work with service providers who provide us with the appropriate safeguards for the security of your personal data and can guarantee that your personal data will be processed strictly in accordance with the European data protection standards. A copy of these appropriate safeguards can be examined at our premises.</Paragraph>
                <Paragraph>If we transfer personal data to third countries, we do so on the basis of a so-called “adequacy decision” of the European Commission or, in the absence of such a decision, on the basis of so-called “standard data protection clauses”, which have also been adopted by the European Commission.</Paragraph>
                <H2>5. Your rights</H2>
                <Paragraph>You have the following rights against us:</Paragraph>
                <H3>5.1. Right of access</H3>
                <Paragraph>You have the right to access as to whether and, if so, what your personal data of you are being processed by us. Is this the case, we will additionally give you access to the following:</Paragraph>
                <List>
                    <Item>the purposes of the processing</Item>
                    <Item>the categories of personal data concerned</Item>
                    <Item>the recipients or categories of recipients of your personal data</Item>
                    <Item>the envisaged period of storage or the criteria used to determine the envisaged period of storage</Item>
                    <Item>any other rights you have</Item>
                    <Item>where we have not obtained the personal data from you: any available information as to their source</Item>
                    <Item>if available: the existence of automated decision-making and any information about the logic involved, as well as the significance and the envisaged consequences of the processing.</Item>
                </List>
                <H3>5.2. Right to rectification</H3>
                <Paragraph>You have the right to rectification and/or completion of the personal data that is being processed by us, if it is inaccurate or incomplete.</Paragraph>
                <H3>5.3. Right to restriction of processing</H3>
                <Paragraph>You have the right to restriction of processing of your personal data, provided that</Paragraph>
                <List>
                    <Item>we verify the accuracy of your personal data being processed by us</Item>
                    <Item>the processing of your personal data is unlawful</Item>
                    <Item>you need the personal data being processed by us for the purpose of prosecution when we no longer need your personal data for the purpose of processing</Item>
                    <Item>you have objected to the processing of your personal data and we are in the process of verifying your objection.</Item>
                </List>
                <H3>5.4. Right to erasure</H3>
                <Paragraph>You have the right to erasure of your personal data, provided that</Paragraph>
                <List>
                    <Item>we no longer need your personal data for its original purpose</Item>
                    <Item>you withdraw your consent and there is no other legal ground for processing your personal data</Item>
                    <Item>you object to the processing of your personal data and, unless it is about direct marketing, there are no overriding grounds for further processing</Item>
                    <Item>the processing of your personal data is unlawful</Item>
                    <Item>the erasure of your personal data is required by law</Item>
                    <Item>your personal data have been collected in relation to the offer of information society services when you were a minor.</Item>
                </List>
                <H3>5.5. Right to notification</H3>
                <Paragraph>If you have asserted your right to rectification, erasure of data or restriction of processing, we will communicate any rectification, erasure of data or restriction of processing to any recipients of your personal data.</Paragraph>
                <H3>5.6. Right to data portability</H3>
                <Paragraph>You have the right to receive your personal data processed by us on the basis of your consent or for the performance of a contract in a structured, commonly used and machine-readable format as well as to transmit them to another controller. If it is technically feasible, you have the right to have them directly transmitted from us to another controller.</Paragraph>
                <H3>5.7. Right to object</H3>
                <Paragraph>You have the right to object to the processing of your personal data on particular grounds. If this is the case, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing.</Paragraph>
                <Paragraph>If your personal data is being processed for the purpose of direct marketing, you have the right to object at any time.</Paragraph>
                <H3>5.8. Right to withdraw</H3>
                <Paragraph>You have the right to withdraw your consent given to us at any time. The withdrawal of the consent does not affect the lawfulness of the processing carried out based on the consent before your withdrawal.</Paragraph>
                <H3>5.9. Right to lodge a complaint with a supervisory authority</H3>
                <Paragraph>Regardless of any other administrative or judicial remedy, you have the right to lodge a complaint with the competent supervisory authority, if you believe that the processing of your personal data by us violates the provisions of the GDPR.</Paragraph>
                <Paragraph>The competent supervisory authority for us is:</Paragraph>
                <Blockquote>
                    <Link to="https://www.lda.bayern.de/" target="_blank">Bayerisches Landesamt für Datenschutzaufsicht (BayLDA)</Link><br />
                    Promenade 18<br />
                    91522 Ansbach<br />
                </Blockquote>
            </Inset>
            <Footer />
        </Container>
    )
};

export default PrivacyPolicy;
